import * as d3 from 'd3'

let chartId = ''
let containerId = ''
let legendId = ''
let svg = null

let radius = null
let chartContainer = null
let arc = null
let arcs = null
let pie = null
let arcOver = null
let textTop = null
let textBottom = null
let resizeObserver = null
let resizeTimer = null
let legend = null
let chartLoading = null
let firstDraw = true

let data = []
let colors = []
let legendNames = []
let margin = {
  top: 40,
  right: 20,
  bottom: 60,
  left: 70
}
let cursorPosition = {
  y: 0,
  x: 0
}
let offsetX = 0
let offsetY = 0

let total = null
let width = null
let height = null

function initPieChart(options) {
  setChartOptions(options)

  drawChart()
}

function setChartOptions(options) {
  data = options.data
  colors = options.colors
  legendNames = options.legendNames
  chartId = options.chartId
  containerId = options.containerId
  legendId = options.legendId

  height = options.height
  total = data.reduce((value, item) => value + item.value, 0)
}

function drawChart() {
  drawLegend()
  calculateWidth()
  calculateHeight()
  setRadius()
  setMargin()
  setTranslateOffset()

  svg = d3.select(`#${chartId}`)
  chartContainer = svg.append('g')
    .attr('class', 'overlay')
    .attr('transform', `translate(${offsetX}, ${offsetY})`)

  textTop = chartContainer.append('text')
    .style('fill', 'var(--v-chartHeaderColor-base)')
    .style('text-anchor', 'middle')
    .style('font-weight', '500')
    .style('font-size', '12px')
    .attr('dy', '.35em')
    .attr('class', 'textTop')
    .text('Total')
    .attr('y', -10)

  textBottom = chartContainer.append('text')
    .style('fill', 'var(--v-chartHeaderColor-base)')
    .style('text-anchor', 'middle')
    .style('font-weight', '500')
    .style('font-size', '12px')
    .attr('dy', '.35em')
    .attr('class', 'textBottom')
    .text(total)
    .attr('y', 10)

  calculateArc()

  pie = d3.pie()
    .value(d => d.value)
    .sort(null)

  arcs = chartContainer.selectAll('path')
    .data(pie(data))
    .enter()
    .append('path')
    .attr('fill', (d, i) => colors[i])
    .attr('d', arc)
    .each(function(d) { this._current = d })

  initResizeObserver()
}

function updatePieChart(options) {
  setChartOptions(options)
  updateChart()
}

function updateChart() {
  calculateWidth()
  calculateHeight()
  clearInterval(resizeTimer)
  textTop.text('Total')
  textBottom.text(total)

  chartContainer.attr('transform', `translate(${offsetX}, ${offsetY})`)

  arcs = arcs.data(pie(data))

  arcs.transition().duration(500).attrTween('d', function (a) {
    const i = d3.interpolate(this._current, a)
    const k = d3.interpolate(arc.outerRadius()(), radius)

    this._current = i(0)
    return t => arc.innerRadius(k(t)/1.8).outerRadius(k(t))(i(t))
  })

  setMouseHandler()
}

function drawLegend() {
  const legendData = colors.map((color, i) => ({
    color,
    name: legendNames[i]
  }))

  legend = d3.select(`#${legendId}`).selectAll('.legend')
    .data(legendData)
    .enter()
    .append('div')
    .attr('class', 'legend')
    .style('display', 'flex')
    .style('padding-bottom', '3px')

  legend
    .append('div')
    .append('span')
    .attr('class', 'circle')
    .html(d => `
      <svg width="10" height="10" style="margin-bottom: 2px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="${d.color}"/>
      </svg>
    `)
    .style('margin-right', '3px')

  legend
    .append('span')
    .text(d => d.name)
    .style('font-size', '12px')
    .style('margin-right', '10px')
    .style('padding-top', '2px')
    .style('color', 'var(--v-chartLegendColor-base)')
}

function calculateWidth() {
  width = document.getElementById(containerId).offsetWidth
}

function calculateHeight() {
  height = document.getElementById(containerId).offsetHeight
}

function setRadius() {
  radius = (width < height ? width / 2 : height / 2) * 0.9
}

function setMargin() {
  if (width < height) {
    margin.top = (height - radius * 2) / 2
    margin.left = 0
  } else {
    margin.left = (width - radius * 2) / 2
    margin.top = 0
  }
}

function setTranslateOffset() {
  if (width === height) {
    offsetX = radius * 1.1
    offsetY = radius * 1.1
  } else if (width < height) {
    offsetX = radius * 1.1
    offsetY = margin.top + radius
  } else {
    offsetX = margin.left + radius
    offsetY = radius * 1.1
  }
}

function calculateArc() {
  arc = d3.arc()
    .innerRadius(radius)
    .outerRadius(radius / 1.8)

  arcOver = d3.arc()
    .innerRadius(radius + 5)
    .outerRadius((radius + 5) / 1.8)
}

function setMouseHandler() {
  arcs
    .on('mouseover', function (d) {
      cursorPosition.x = d.pageX
      cursorPosition.y = d.pageY
      
      d3.select(this).transition().duration(200).attr('d', arcOver)
      textTop.text(d3.select(this).datum().data.name).attr('y', -10)

      textBottom.text(d3.select(this).datum().data.value).attr('y', 10)
    })
    .on('mouseout', function (d) {
      if (cursorPosition.x === d.pageX && cursorPosition.y === d.pageY) {
        return
      }
      
      cursorPosition.x = d.pageX
      cursorPosition.y = d.pageY
      
      d3.select(this).transition().duration(100).attr('d', arc)

      textTop.text('Total').attr('y', -10)

      textBottom.text(total).attr('y', 10)
    })
}

function showPieLoading() {
  chartLoading = svg.append('g').attr('class', 'chart-loading')
    .attr('width', width)
    .attr('height', height)

  chartLoading.append('rect')
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#000')
    .attr('opacity', 0.5)

  chartLoading.append('text')
    .attr('transform', `translate(${width / 2 - 30}, ${height / 2})`)
    .attr('fill', '#fff')
    .attr('font-size', '15px')
    .attr('font-family', 'Fira Sans')
    .text('Loading...')
}

function hidePieLoading() {
  chartLoading.remove()
  chartLoading = null
}

function initResizeObserver() {
  resizeObserver = new ResizeObserver(() => {
    clearInterval(resizeTimer)

    resizeTimer = setInterval(() => {
      if (firstDraw) {
        firstDraw = false
        return
      }
      calculateWidth()
      calculateHeight()
      setRadius()
      setMargin()
      setTranslateOffset()
      calculateArc()
      updateChart()
    }, 50)
  })

  resizeObserver.observe(document.getElementById(containerId))
}

function unInitPieChart() {
  resizeObserver.disconnect(document.getElementById(containerId))
  svg.remove()
  svg = null
}

export { initPieChart, updatePieChart, showPieLoading, hidePieLoading, unInitPieChart }