import * as d3 from 'd3'

let chartId = ''
let containerId = ''
let svg = null
let yTitle = null
let chartLayer = null
let chartLoading = null
let overLayer = null
let eventCapture = null

let groups = null
let bars = null
let resizeObserver = null
let resizeTimer = null
let tooltip = null
let tooltipTimer = null
let legend = null
let legends = null
let barTitle = null

let data = []
let keys = []
let colors = []
let legendNames = []
let timeGroupId = null
let margin = {
  top: 40,
  right: 20,
  bottom: 60,
  left: 70
}

let stack = null
let yScale = null
let xScale = null
let yAxis = null
let xAxis = null
let width = null
let height = null
let series = null
let oldTooltipPosition = null

const locale = d3.timeFormatLocale({
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['am', 'pm'],
  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
})

const dateFormats = {
  'byMonth': locale.utcFormat('%b \'%y'),
  'byDay': locale.utcFormat('%e. %b'),
  'byHour': locale.utcFormat('%I:%M %p'),
}

const dateFormatsForTooltip = {
  'byMonth': locale.utcFormat('%B %Y'),
  'byDay': locale.utcFormat('%A, %b %e, %Y'),
  'byHour': locale.utcFormat('%A, %b %e, %H:%M'),
}

function initChart(options) {
  setChartOptions(options)

  if (!!svg) {
    updateChart()
  } else {
    calculateWidth()
    drawChart()
  }
}

function setChartOptions(options) {
  data = options.data
  keys = options.keys
  colors = options.colors
  legendNames = options.legendNames
  margin = options.margin
  chartId = options.chartId
  containerId = options.containerId
  timeGroupId = options.timeGroupId

  height = options.height
}

function drawChart() {
  svg = d3.select(`#${chartId}`)
  tooltip = d3.select('#tooltip')
    .style('opacity', 0)

  yTitle = svg.append('g').attr('class', 'y title')
    .attr('transform', `translate(${margin.left / 2}, ${margin.top})`)
    .attr('height', height - (margin.top + margin.bottom))
    .attr('width', margin.left)

  yTitle.append('text')
    .attr('fill', '#fff')
    .attr('transform', 'rotate(-90)')
    .attr('x', -(height - (margin.top + margin.bottom)) / 1.8)
    .attr('font-size', '12px')
    .attr('font-family', 'Fira Sans')
    .text('Calls')

  overLayer = svg.append('g').attr('class', 'stacks')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  legend = svg.append('g').attr('class', 'legend')
    .attr('transform', `translate(0, ${height - margin.bottom / 1.6})`)

  xScale = d3.scaleBand()
    .range([0, width - margin.left - margin.right])
    .domain(data.map(d => d.timestamp * 1000))
    .padding(0.4)

  yScale = d3.scaleLinear()
    .range([height - (margin.top + margin.bottom), 0])
    .nice(3)

  xAxis = d3.axisBottom(xScale)

  setInvertFunc()

  yAxis = d3.axisLeft(yScale)
    .tickSize(-(width - margin.left - margin.right))
    .ticks(3)

  overLayer.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0, ${height - margin.bottom - margin.top})`)
    .call(xAxis)

  overLayer.append('g')
    .attr('class', 'y axis')
    .call(yAxis)

  overLayer.append('g').attr('class', 'stacks')

  chartLayer = svg.append('g').attr('class', 'chart-layer')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)

  eventCapture = chartLayer.append('rect')
    .attr('width', width - (margin.left + margin.right))
    .attr('height', height - (margin.top + margin.bottom))
    .attr('opacity', 0)

  stack = d3
    .stack()
    .keys(keys)
    .order(d3.stackOrderNone)
    .offset(d3.stackOffsetNone)

  stylingChart()
  drawLegend()
  calculateWidth()
  calculateSeries()
  updateStacks()
  setTooltipHandler()
  initResizeObserver()
}

function setInvertFunc() {
  if (!data.length) {
    return
  }

  xScale.invert = (() => {
    const domain = xScale.domain()
    const range = xScale.range()
    const scale = d3.scaleQuantize().domain(range).range(domain)

    return x => scale(x)
  })()
}

function showLoading() {
  chartLoading = svg.append('g').attr('class', 'chart-loading')
    .attr('transform', `translate(${margin.left}, ${margin.top})`)
    .attr('width', width - (margin.left + margin.right))
    .attr('height', height - (margin.top + margin.bottom))

  chartLoading.append('rect')
    .attr('width', width - (margin.left + margin.right))
    .attr('height', height - (margin.top + margin.bottom))
    .attr('fill', '#000')
    .attr('opacity', 0.5)

  chartLoading.append('text')
    .attr('transform', `translate(${(width - (margin.left + margin.right)) / 2 - 50}, ${(height - (margin.top + margin.bottom)) / 2})`)
    .attr('fill', '#fff')
    .attr('font-size', '15px')
    .attr('font-family', 'Fira Sans')
    .text('Loading...')
}

function hideLoading() {
  chartLoading.remove()
  chartLoading = null
}

function setTooltipHandler() {
  eventCapture
    .on('mouseover', () => {
      clearInterval(tooltipTimer)
      tooltip.style('opacity', 1)
    })
    .on('mouseout', () => {
      tooltipTimer = setInterval(() => {
        clearInterval(tooltipTimer)
        tooltip.style('opacity', 0)
      }, 500)
    })
    .on('mousemove', e => {
      const xy = d3.pointer(e)
      const d = xScale.invert(xy[0])
      const nx = xScale(d)

      if (oldTooltipPosition === nx) {
        return
      }

      oldTooltipPosition = nx

      const item = data.find(i => i.timestamp * 1000 === d)

      tooltip.transition().duration(100)
        .style('left', `${nx}px`)
        .style('top', `${yScale(item.total) + 20}px`)

      tooltip.html(`
        ${dateFormatsForTooltip[timeGroupId](item.timestamp * 1000)}<br/>
        <span style="color: #F4F0F9">●</span> Total calls ${item.total}<br/>
        <span style="color: ${colors[2]}">●</span> Converted ${item.converted}<br/>
        <span style="color: ${colors[1]}">●</span> Not Converted ${item.not_converted}<br/>
        <span style="color: ${colors[0]}">●</span> No Answer ${item.no_answer}
      `)

      tooltip.exit().remove()
    })
}

function updateBars(childRects) {
  bars = childRects
    .selectAll('rect')
    .data(d => d, d => d.data.timestamp)
    .join(
      enter =>
        enter
          .append('rect')
          .attr('id', d => d.data.timestamp)
          .attr('class', 'bar')
          .attr('x', d => xScale(d.data.timestamp * 1000))
          .attr('y', yScale(0))
          .attr('width', xScale.bandwidth())
          .call(enter =>
            enter.transition().duration(500)
              .attr('y', d => yScale(d[1]))
              .attr('height', d => yScale(d[0]) - yScale(d[1]))
              .attr('x', d => xScale(d.data.timestamp * 1000))
              .attr('width', xScale.bandwidth())
          ),
      update =>
        update.call(update =>
          update.transition().duration(500)
            .attr('y', d => yScale(d[1]))
            .attr('height', d => yScale(d[0]) - yScale(d[1]))
            .attr('x', d => xScale(d.data.timestamp * 1000))
            .attr('width', xScale.bandwidth())
        ),
      exit =>
        exit.call(exit =>
          exit
            .attr('y', height)
            .attr('height', 0)
            .remove()
        )
    )
}

function updateStacks() {
  let qntByWidth = width / 60
  let separation = Math.ceil(data.length / qntByWidth)

  const yMax = d3.max(series[series.length - 1], d => d[1])
  yScale.domain([0, yMax])
    .nice(3)

  xScale.domain(data.map(d => d.timestamp * 1000))

  overLayer.select('.y').transition().duration(500).call(yAxis)
  overLayer.select('.x').transition().duration(500).call(xAxis
    .tickFormat(d => {
      const currentItem = data.find(item => item.timestamp * 1000 === d)
      let date = null
      if (currentItem) {
        if (timeGroupId === 'byHour') {
          let hour = currentItem.date.split(' ')[1]

          if (hour === '00') {
            return dateFormats['byDay'](d)
          }
        }
      }
      return dateFormats[timeGroupId](d)
    })
    .tickValues(xScale.domain().filter((d, i) => !(i%separation)))
  )


  stylingChart()
  groups = overLayer.selectAll('g.stacks')
    .selectAll('.stack')
    .data(series, d => d.key)

  groups.join(
    enter => {
      const barsEnter = enter.append('g').attr('class', 'stack')

      barsEnter
        .append('g')
        .attr('class', 'bars')
        .attr('fill', d => colors[d.index])

      updateBars(barsEnter.select('.bars'))

      return enter
    },
    update => updateBars(update.select('.bars')),
    exit => exit.remove()
  )

  drawBarTitle()
}

function drawBarTitle() {
  if (barTitle) {
    barTitle.remove()
  }

  barTitle = groups.selectAll('g.rect')
    .data((d, i) => i === keys.length - 1 ? d : [])
    .enter()
    .append('text')
    .attr('class', 'label')
    .style('fill', 'var(--v-chartStackLabelColor-base)')
    .style('text-anchor', 'middle')
    .attr('font-size', '11px')
    .attr('x', d => (xScale((d.data.timestamp * 1000)) + xScale.bandwidth() / 2))
    .attr('y', d => yScale(d[1]) - 10)
    .text(d => d.data.total ? d.data.total : '')
}



function calculateSeries() {
  stack = d3
    .stack()
    .keys(keys)
    .order(d3.stackOrderNone)
    .offset(d3.stackOffsetNone)

  series = stack(data)
}

function calculateWidth() {
  width = document.getElementById(containerId).offsetWidth
}

function drawLegend() {

  const legendData = colors.map((color, i) => ({
    color,
    name: legendNames[i]
  })).reverse()

  legends = legend.selectAll('g.legend legend').data(legendData)

  const legendBox = legends.enter().append('g')


  const circles = legendBox.append('circle')
    .attr('r', 5)
    .attr('fill', d => d.color)

  const text = legendBox.append('text')
    .attr('dx', () => 10)
    .attr('dy', () => 4.5)
    .attr('fill', 'var(--v-chartLegendColor-base)')
    .attr('font-size', '12px')
    .text(d => d.name)

  calculateLegendPosition()
}

function calculateLegendPosition() {
  let x_offset = 0
  const paddingRight = 8
  svg.selectAll('g.legend g').attr('transform', function (d, i) {
    const x_pos = d3.select(this).node().getBoundingClientRect().width + paddingRight
    x_offset = x_offset + x_pos

    return `translate(${x_offset - x_pos + margin.left}, 10)`
  })

  const legendWidth = legend.node().getBoundingClientRect().width
  const legendOffsetX = (width - margin.left - margin.right - legendWidth - paddingRight) / 2

  legend.attr('transform', `translate(${legendOffsetX}, ${height - margin.bottom / 1.6})`)
}

function initResizeObserver() {
  resizeObserver = new ResizeObserver(() => {
    clearInterval(resizeTimer)

    resizeTimer = setInterval(() => {
      updateChart()
    }, 50)
  })

  resizeObserver.observe(document.getElementById(containerId))
}

function updateChart() {
  calculateWidth()
  calculateSeries()
  xScale.range([0, width - margin.left - margin.right])
  yAxis.tickSize(-(width - margin.left - margin.right))
  eventCapture.attr('width', width - (margin.left + margin.right))
  updateStacks()
  calculateLegendPosition()
  clearInterval(resizeTimer)
  setInvertFunc()

  if (chartLoading) {
    hideLoading()
    showLoading()
  }
}

function stylingChart() {
  svg.selectAll('.tick line').attr('stroke', 'var(--v-chartLineColor-base)')
  svg.selectAll('.tick text')
    .attr('color', 'var(--v-chartAxisXLabels-base)')
    .attr('font-family', 'Fira Sans')

  overLayer.selectAll('.domain').remove()
  svg.selectAll('.x.axis .tick line').remove()
}

function unInitChart() {
  resizeObserver.disconnect(document.getElementById(containerId))
  svg.remove()
  svg = null
}

export {
  initChart, showLoading, hideLoading, unInitChart
}