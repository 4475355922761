<template>
  <v-col class="pl-15" cols="3" md="3">
    <v-card
      class="transparent chart-card pie-chart rounded-20 pa-13 d-flex flex-column"
      outlined
      flat
    >
      <div class="bar-chart-title d-flex justify-center flex-shrink-1 pb-4 chartHeaderColor--text">
        total calls
      </div>
      
      <div id="pie_chart_container" class="flex-grow-1">
        <svg id="pie_chart"></svg>
      </div>
      
      <div id="pie_legend" class="d-flex flex-wrap flex-shrink-1 justify-center mt-2"></div>
    </v-card>
  </v-col>
</template>

<script>
import { initPieChart, updatePieChart, showPieLoading, hidePieLoading, unInitPieChart } from '@/plugins/pieChart'

export default {
  name: 'PieChart',
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    options() {
      return {
        data: this.data,
        legendNames: ['Converted', 'Not Converted', 'No Answer'],
        colors: ['#6F4FCC', '#FFDA1A', '#F06269'],
        chartId: 'pie_chart',
        containerId: 'pie_chart_container',
        legendId: 'pie_legend',
      }
    },
  },
  mounted() {
    initPieChart(this.options)
    if (this.isLoading) {
      showPieLoading()
    }
  },
  watch: {
    isLoading: {
      handler(loading) {
        if (loading) {
          showPieLoading()
        } else {
          hidePieLoading()
          updatePieChart(this.options)
        }
      },
    },
  },
  beforeDestroy() {
    unInitPieChart()
  },
}
</script>
