<template>
  <v-col cols="12" :md="colOption">
    <v-card
      id="chart_container"
      class="transparent chart-card pie-chart rounded-20 pt-10"
      outlined
      flat
    >
      <div class="bar-chart-title d-flex justify-center py-3 chartHeaderColor--text">
        calls by: {{ timeGroupId.split('by')[1] }}
      </div>
      
      <div id="tooltip"></div>
      
      <svg id="stacked_chart"></svg>
    </v-card>
  </v-col>
</template>

<script>
import { initChart, showLoading, hideLoading, unInitChart } from '@/plugins/stackBarChart'

export default {
  inheritAttrs: false,
  props: {
    timeGroupId: {
      type: String,
      default: 'byHour'
    },
    data: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    colOption: {
      type: Number,
      default: 12
    }
  },
  name: 'StackBarChart',
  computed: {
    options() {
      return {
        data: this.data,
        keys: ['no_answer', 'not_converted', 'converted'],
        legendNames: ['No Answer', 'Not Converted', 'Converted'],
        colors: ['#F06269', '#FFDA1A', '#6F4FCC'],
        margin: {
          top: 25,
          right: 50,
          bottom: 110,
          left: 70
        },
        chartId: 'stacked_chart',
        containerId: 'chart_container',
        height: 300,
        timeGroupId: this.timeGroupId
      }
    },
  },
  mounted() {
    initChart(this.options)
    if (this.isLoading) {
      showLoading()
    }
  },
  watch: {
    isLoading: {
      handler(loading) {
        if (loading) {
          showLoading()
        } else {
          hideLoading()
          initChart(this.options)
        }
      },
    },
  },
  beforeDestroy() {
    unInitChart()
  },
}
</script>
