<template>
  <v-col class="pb-12 pb-sm-15" cols="12">
    <v-card
      class="transparent chart-card rounded-20 pa-13"
      outlined
      flat
    >
      <div id="chartTotalContainer">
        <div class="bar-chart-title d-flex justify-center chartHeaderColor--text">
          total calls
        </div>
        
        <div id="bar_chart_container">
          <div id="bar_tooltip"></div>
          
          <div id="bar_chart" class="d-block"></div>
        </div>
        
        <div id="bar_legend" class="d-flex flex-wrap justify-center"></div>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import { initTotalBarChart, showTotalBarChartLoading, hideTotalBarChartLoading, updateTotalBarChart, destroyTotalBarChart } from '@/plugins/horizontalBarChart'

export default {
  name: 'HorizontalBarChart',
  inheritAttrs: false,
  props: {
    data: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    initTotalBarChart(this.data)
    if (this.isLoading) {
      showTotalBarChartLoading()
    }
  },
  watch: {
    isLoading: {
      handler(loading) {
        if (loading) {
          showTotalBarChartLoading()
        } else {
          hideTotalBarChartLoading()
          updateTotalBarChart(this.data)
        }
      },
    },
  },
  beforeDestroy() {
    destroyTotalBarChart()
  },
}
</script>
