import * as d3 from 'd3'

function divergingBarChart() {
  const margin = { top: 25, right: 60, bottom: 25, left: 0 }
  let width = d3.select('#bar_chart_container').node().getBoundingClientRect().width
  const height = d3.select('#bar_chart').node().getBoundingClientRect().height
  const barHeight = height - margin.top - margin.bottom
  const paddingTotalTitle = 10
  const dataValue = d => +d.value
  const nameValue = d => d.name
  const color = ['#6F4FCC', '#FFDA1A', '#F06269']
  const titleColor = ['#eeeeee', '#342d48', '#eeeeee']
  let firstDraw = true
  let svg = null
  let layer = null
  let tooltip = null
  let rectG = null
  let dataRects = null
  let tooltipTimer = null
  let oldTooltipPosition = null
  let barScale = null
  let chartLoading = null
  let totalValue = null

  function chart(selection) {
    selection.each(function(data) {
      if (!data) {
        return
      }

      data = data.map(d => ({ value: dataValue(d), name: nameValue(d) }))

      totalValue = d3.sum(data, d => d.value)
      const widthTotalTitle = `${totalValue}`.length * 7 + paddingTotalTitle
      barScale = d3.scaleLinear()
        .domain([0, totalValue ? totalValue : 100])
        .range([0, width - widthTotalTitle])


      tooltip = d3.select('#bar_tooltip')
        .style('opacity', 0)

      svg = d3.select(this).selectAll('svg').data([data])


      const gEnter = svg.enter().append('svg').append('g').attr('class', 'layer')

      gEnter.append('g').attr('class', 'rects')
        .selectAll('.data-rects').data(data).enter()
        .append('rect').attr('class', 'data-rects')

      gEnter.selectAll('text.legend')
        .data(data).enter()
        .append('text')
        .attr('class', 'legend')
        .attr('font-size', 12)

      gEnter.selectAll('text.total')
        .data([totalValue]).enter()
        .append('text')
        .attr('class', 'total')
        .attr('font-size', 12)

      svg = selection.select('svg')
      svg.attr('width', width).attr('height', height)

      layer = svg.selectAll('g.layer')
        .attr('transform', `translate(0, ${margin.top})`)

      rectG = layer.select('g.rects')
      dataRects = rectG.selectAll('.data-rects').data(data, d => d.name)

      dataRects.exit().remove()

      dataRects.enter().append('rect').attr('class', 'data-rects')

      rectG.selectAll('.data-rects').transition().duration(500)
        .attr('x', (d, i) => data.slice(0, i).reduce((a, d) => a + barScale(d.value), 1))
        .attr('width', d => +d.value ? Math.max(barScale(d.value), 1) : 0)
        .attr('height', barHeight)
        .attr('fill', (_, i) => color[i])


      const legendX = (d, i) => data.slice(0, i).reduce(
        (a, d) => a + barScale(d.value),
        (Math.max((barScale(d.value)), 1) / 2)
      )
      const legendText = layer.selectAll('text.legend').data(data, d => d.name)

      legendText.enter()
        .append('text')
        .attr('class', 'legend')
        .attr('font-size', 12)

      if(firstDraw) {
        legendText.attr('x', legendX)
      } else {
        legendText.transition().duration(500).attr('x', legendX)
      }

      legendText
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('font-weight', 600)
        .attr('fill', (_, i) => titleColor[i])
        .text(d => d3.format(',')(d.value))
        .attr('y', barHeight / 2)
        .style('opacity', function (d) {
          if (!d.value) {
            return 0
          }
          const chartWidth = width - margin.left - margin.right
          const textWidth = d3.select(this).node().getBoundingClientRect().width
          const barWidth = (d.value / totalValue) * chartWidth
          return barWidth < textWidth + 10 ? '0' : '1'
        })

      const legendTotal = layer.selectAll('text.total').data([totalValue])

      legendTotal.enter()
        .append('text')
        .attr('class', 'total')
        .attr('font-size', 12)

      if(firstDraw) {
        legendTotal.attr('x', width - widthTotalTitle + paddingTotalTitle)
      } else {
        legendTotal.transition().duration(500)
          .attr('x', width - widthTotalTitle + paddingTotalTitle)
      }

      legendTotal
        .attr('alignment-baseline', 'middle')
        .text(d3.format(',')(totalValue))
        .attr('y', barHeight / 2)
        .attr('fill', 'var(--v-chartStackLabelColor-base)')
        .attr('opacity', totalValue ? 1 : 0)

      setTooltipHandler()

      firstDraw = false
    })
  }

  chart.width = function(_) {
    if (!arguments.length) return width
    width = _
    return chart
  }

  chart.showLoading = function() {
    chartLoading = d3.select('#bar_chart').select('svg').append('g').attr('class', 'chart-loading')
      .attr('width', width)
      .attr('height', height)

    chartLoading.append('rect')
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#000')
      .attr('opacity', 0.5)

    chartLoading.append('text')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)
      .attr('fill', '#fff')
      .attr('font-size', '15px')
      .attr('font-family', 'Fira Sans')
      .text('Loading...')
  }

  chart.hideLoading = function() {
    chartLoading.remove()
    chartLoading = null
  }

  function setTooltipHandler() {
    svg.selectAll('rect.data-rects')
      .on('mouseover', function(e, data) {
        clearInterval(tooltipTimer)

        tooltip.style('opacity', 1)

        tooltip.text(`
          ${data.name}: ${data.value} (${d3.format('.0%')(data.value / totalValue)})
        `)

        const tooltipWidth = d3.select('#bar_tooltip').node().getBoundingClientRect().width

        const bar = d3.select(e.target).attr('width')
        const barX = d3.select(e.target).attr('x')

        const tooltipPosition = width / 2 < barX
          ? barX - tooltipWidth + bar / 2
          : bar / 2 + barX

        tooltip.transition().duration(100)
          .style('left', `${tooltipPosition}px`)
          .style('top', `${margin.top - barHeight / 2}px`)
      })
      .on('mouseout', () => {
        tooltipTimer = setInterval(() => {
          clearInterval(tooltipTimer)
          tooltip.style('opacity', 0)
        }, 500)
      })
  }

  return chart
}

function drawLegend() {
  let legend = null
  const legendNames = ['No Answer', 'Not Converted', 'Converted']
  const colors = ['#F06269', '#FFDA1A', '#6F4FCC']

  const legendData = colors.map((color, i) => ({
    color,
    name: legendNames[i]
  })).reverse()

  legend = d3.select('#bar_legend').selectAll('.legend')
    .data(legendData)
    .enter()
    .append('div')
    .attr('class', 'legend')
    .style('display', 'flex')
    .style('padding-bottom', '3px')

  legend
    .append('div')
    .append('span')
    .attr('class', 'circle')
    .html(d => `
      <svg width="10" height="10" style="margin-bottom: 2px" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="5" cy="5" r="5" fill="${d.color}"/>
      </svg>
    `)
    .style('margin-right', '3px')

  legend
    .append('span')
    .text(d => d.name)
    .style('font-size', '12px')
    .style('margin-right', '10px')
    .style('padding-top', '2px')
    .style('color', 'var(--v-chartLegendColor-base)')
}

let chartInit = null

function initTotalBarChart(data) {
  chartInit = divergingBarChart()
  drawLegend()
  d3.select('#bar_chart').datum(data).call(chartInit)
  d3.select(window).on('resize', resize)
}

function updateTotalBarChart(data) {
  d3.select('#bar_chart').datum(data).call(chartInit)
}

function showTotalBarChartLoading() {
  chartInit.showLoading()
}

function hideTotalBarChartLoading() {
  chartInit.hideLoading()
}

function resize() {
  if (d3.select('#bar_chart').empty()) {
    return
  }

  const w = +d3.select('#bar_chart').style('width').replace(/(px)/g, '')

  if (!w) {
    return
  }
  chartInit.width(w)
  d3.select('#bar_chart').call(chartInit)
}

function destroyTotalBarChart() {
  d3.select(window).on('resize', null)
  chartInit = null
}

export { initTotalBarChart, showTotalBarChartLoading, hideTotalBarChartLoading, updateTotalBarChart, destroyTotalBarChart }